<template>
  <v-main class="container-prosker">
    <v-row>
      <v-col md="6" cols="12">
        <v-card class="mt-10 pb-10 pt-5 px-5">
          <h1 class="text-center">{{ $t("contactUs") }}</h1>
          <h5 class="text-center">
            {{ $t("haveQuestionsCommentsContactUs") }}
          </h5>
          <v-container>
            <v-text-field
              :label="$t('name')"
              required
              color="#59D79D"
              :hide-details="errorName.length === 0"
              :error-count="errorName.length"
              :error-messages="errorName"
              v-model="name"
            ></v-text-field>
            <v-text-field
              :label="$t('lastName')"
              required
              color="#59D79D"
              :hide-details="errorLastName.length === 0"
              :error-count="errorLastName.length"
              :error-messages="errorLastName"
              v-model="lastName"
            ></v-text-field>
            <v-text-field
              :label="$t('email')"
              required
              color="#59D79D"
              :hide-details="errorEmail.length === 0"
              :error-count="errorEmail.length"
              :error-messages="errorEmail"
              v-model="email"
            ></v-text-field>
            <v-textarea
              class="mt-8"
              solo
              :label="$t('message')"
              required
              color="#59D79D"
              :hide-details="errorMessage.length === 0"
              :error-count="errorMessage.length"
              :error-messages="errorMessage"
              v-model="message"
            ></v-textarea>
            <v-col offset="6" cols="6">
              <save-button :save="onSubmit" :loading="loading" :message="$t('send')" class="float-end"/>
            </v-col>
          </v-container>
        </v-card>
      </v-col>
      <v-col md="6" cols="12">
        <v-row class="mt-10 pb-10 pt-2 px-5">
          <v-container>
            <h1 class="text-center">{{ $t("howCanWeHelp") }}</h1>
            <h5 class="text-center">{{ $t("howCanHelpText") }}</h5>
            <v-col cols="12">
              <v-list-item v-for="(item, i) in items" :key="i">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <span>{{ item.text }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <span>
                    {{ $t('sendUsWhatsapp') }}
                    <br/>
                    <a href="https://api.whatsapp.com/send?phone=59898702882" target="_blank">
                      <v-icon color="#59D79D">mdi-whatsapp</v-icon><span class="blue--text">PROSK</span>
                    </a>
                  </span>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" class="text-center mt-15">
              <v-btn large icon href="https://www.facebook.com/prosk.com.uy/" target="_blank" class="me-2">
                <v-icon large color="#59D79D">mdi-facebook</v-icon>
              </v-btn>
              <v-btn large icon href="https://www.linkedin.com/company/prosk/" target="_blank" class="me-2">
                <v-icon large color="#59D79D">mdi-linkedin</v-icon>
              </v-btn>
              <v-btn large icon href="https://instagram.com/prosk.uy?igshid=YmMyMTA2M2Y=" target="_blank" class="me-2">
                <v-icon large color="#59D79D">mdi-instagram</v-icon>
              </v-btn>
            </v-col>
          </v-container>
        </v-row>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';

export default {
  name: 'ContactPage',
  components: { SaveButton },
  mixins: [errorDialog],
  data () {
    return {
      items: [
        {
          icon: 'mdi-map-marker',
          text: 'Av. Rivera 2248, Montevideo - Uruguay'
        },
        {
          icon: 'mdi-email-outline',
          text: 'info@prosk.com.uy'
        }
      ],
      name: null,
      errorName: [],
      lastName: null,
      errorLastName: [],
      email: null,
      errorEmail: [],
      message: null,
      errorMessage: [],
      loading: false
    };
  },
  created () {
    if (window.location.pathname !== '/perfil') {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  },
  methods: {
    validEmail: function (email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    resetErrors () {
      this.errorName = [];
      this.errorLastName = [];
      this.errorEmail = [];
      this.errorMessage = [];
    },
    reset () {
      this.name = null;
      this.lastName = null;
      this.email = null;
      this.message = null;
    },
    onSubmit () {
      this.resetErrors();
      if (
        this.name &&
        this.lastName &&
        this.email &&
        this.message &&
        this.validEmail(this.email)
      ) {
        this.loading = true;
        const data = {
          name: this.name,
          last_name: this.lastName,
          message: this.message,
          email: this.email
        };
        const url = `${process.env.VUE_APP_BASE_URL}/api/contact-page`;
        axios
          .post(url, data)
          .then(() => {
            this.reset();
            this.$swal.fire({
              title: this.$t('awesome'),
              text: this.$t('thankContactingUsWillReplySoon'),
              confirmButtonColor: '#19AD93'
            });
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.showError(error);
          });
      }
      if (!this.email) {
        this.errorEmail.push(this.$t('emailRequired'));
      }
      if (!this.validEmail(this.email)) {
        this.errorEmail.push(this.$t('enterValidEmail'));
      }
      if (!this.name) {
        this.errorName.push(this.$t('fieldNameRequired'));
      }
      if (!this.lastName) {
        this.errorLastName.push(this.$t('fieldLastNameRequired'));
      }
      if (!this.message) {
        this.errorMessage.push(this.$t('fieldMessageRequired'));
      }
    }
  }
};
</script>
